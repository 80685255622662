import { RenderComponent } from '@vue-storefront/core'
import { Logger } from '@vue-storefront/core'
import { Image, MappedImage } from '../types'

const extractNestedItem = (item) => (item.fields ? item.fields : item)

const supportCompose = (item) => ({
  ...item,
  component: item.content.fields.component,
  content: item.content.fields.content,
})

export const getImageObject = ({ fields, metadata }: Image): MappedImage => ({
  url: fields.file.url,
  alt: fields.title,
  metadata: {
    ...metadata,
    width: fields.file.details.image.width,
    height: fields.file.details.image.height,
    size: fields.file.details.size,
    format: fields.file.contentType,
  },
})

const filterProps = ['sys', '_meta']

export const extractComponents = (items: any[]): RenderComponent[] => {
  if (!items || !items.length) {
    Logger.error('No content items provided for render')
    return []
  }
  return items.map((item) => {
    item = extractNestedItem(item)
    if (!(item.content instanceof Array) && !item.component && item.seo) {
      item = supportCompose(item)
    }
    return {
      componentName: item.component,
      props: ((data) => {
        filterProps.forEach((prop) => delete data[prop])
        if (data.image) {
          data.image = getImageObject(data.image)
        }
        if (data.images && Array.isArray(data.images)) {
          data.images = data.images.map(getImageObject)
        }
        if (data.content && Array.isArray(data.content)) {
          data.content = data.content.map(extractNestedItem)
        }
        if (data.items && Array.isArray(data.items)) {
          data.items = data.items.map((subItem) => {
            subItem = extractNestedItem(subItem)
            if (subItem.image && subItem.image.fields) {
              subItem.image = getImageObject(subItem.image)
            }
            return subItem
          })
        }

        if (data.component) {
          delete data.component
        }
        return data
      })(JSON.parse(JSON.stringify(item))),
    }
  })
}
